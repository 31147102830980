
/**
 * Menu
 */

const selector = '#nav-menu-trigger'

function init() {

}

function toggle(){
  return document.querySelector(selector).checked =
    document.querySelector(selector).checked ?
      false : true
}

function close() {
  document.querySelector(selector).checked = false
}


document.addEventListener('DOMContentLoaded', init)


const publicInterface = {
  toggle: toggle,
  close: close,
}

export default function () { return publicInterface }
