'use strict';

import scrollToAnchor from './scrollToAnchor'

import initialScroll from './initialScroll';


console.log('loaded')
/**
 * unrelated detection code that should be somewhere else
 */
console.log(
  "navigator.userAgent.match(/Edge\/\d+/) || navigator.appVersion.match('Trident')",
  navigator.userAgent.match(/Edge\/\d+/) || navigator.appVersion.match('Trident'))

if (navigator.userAgent.match(/Edge\/\d+/) || navigator.appVersion.match('Trident')) {
  document.body.className = document.body.className + ' isIEEdge'
} else {
  document.body.className = document.body.className + ' isNotIEEdge'
}
/** END of unrelated detection code that should be somewhere else */
/*
if (navigator.appVersion.match('Trident')) {
  console.log('trident')
  window.alert('Vous utilisez un navigateur obsolète. Le site risque de ne pas s\'afficher correctement.')
}
*/


// import menu from './menu'

// import showMore from './showMore'

// import newWelcomeShowMore from './newWelcomeShowMore'

