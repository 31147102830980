
const $ = window.jQuery


export default function scrollTo(selector) {
  /**
   * give an option to request a scroll to top of page
   */
  if (selector === 'top') {
    // document.body.scrollTop = document.documentElement.scrollTop = 0;

    $('html, body').animate({
      scrollTop: 0,
    }, 580, 'swing');

  /**
   * give an option to request a croll to top
   */
  } else {
    $('html, body').animate({
      scrollTop: $(selector).offset().top
    }, 580, 'swing');
  }
}
