'use strict';

Array.prototype.find = function(cb) {
  for (let i = 0; i < this.length; i++) {
    if (cb(this[i])) {
      return this[i]
    }
  }
}

import scrollTo from './scrollTo'
import menu from './menu'

console.log('menu')
console.log(menu)

const selector = '#menu-menu-principal a'
const $ = window.jQuery
const siteurl = window.WP_GLOBALS.siteurl
const homepageSectionLists = window.WP_GLOBALS.homepageSectionLists

const isHomepage = () =>
  window.location.href === `${WP_GLOBALS.siteurl}/` ||
  window.location.href === `${WP_GLOBALS.siteurl}` ||
  window.location.href.match(new RegExp (`${WP_GLOBALS.siteurl}/#`));


window.isHomepage = isHomepage

const attachListeners = () => {
  $(selector).click(handleNavRequest)
  console.log('attachListeners')
}


const handleNavRequest = (e) => {
  console.log('handleNavRequest')
  const realTarget = e.currentTarget.href
  console.log(realTarget)
  const anchorTarget = realTarget.match(/([^\/]*)\/$/)[1]
  const target = $(`#${anchorTarget}`)

  console.log(isHomepage())
  console.log(target.get(0))

  if (target.get(0)) {
    e.preventDefault()
    menu().close()
    scrollTo(`#${anchorTarget}`)
  } else if (isHomepage() &&
      (realTarget === siteurl || realTarget === `${siteurl}/`)
    ) {
    console.log('else if (isHomepage())')
    // prevent navigating for nothing, and scroll to top of page
    e.preventDefault()
    menu().close()
    // repalce the URL in location bar like a savage
    window.location.href = `#${anchorTarget}`;
    scrollTo('top')
  } else {
    /**
      * if we're not on the homepage
      * we need to modfy slightly the requested url
      * so that instead of ending up on the single of the page/home page section
      * we end up on the homepage and directly on the requested section
      * in short :
      * when NOT on the homepage, if a link towards a
      * 'page'(/<homepage_section>) that is on the homepage is clicked we should
      * change the requested url towards :
      * site.com/<requested_page> -> site.com/#<requested_page>
      */
    // check if requested is homepage section
    const isRequestedAHomepageSection = homepageSectionLists
      .find(p => p.homepage_section.post_name === anchorTarget)

    console.log('isRequestedAHomepageSection', isRequestedAHomepageSection)

    if (isRequestedAHomepageSection) {
     const modifiedURL = `${siteurl}/#${anchorTarget}`
     e.preventDefault()
     window.location.replace(modifiedURL)
     menu().close()

    } else {
     // here really lies the noop
     // if no js this should not break eveything
     // the navigation is going to hppened and the user
     // will end up on the single
     /* noop! let it go throught with the network request */
    }
  }
}

const init = () => {
  // conditionnal load
  // if (!navigator.appVersion.match('Trident')) {
    console.log('init fn ofr non trident')
    attachListeners()
  // }
}



window.addEventListener('DOMContentLoaded', init)
// document.addEventListener('load', function() {
  // init()
// })
