

/**
 * the point of this module is to handle request towards the homepage section
 * if a user on the page single 'foo' clicks the mission menu item,
 * the user shloud be brought on the homepage and then scrolled into the correct section
 */

import scrollTo from './scrollTo'

const hash = window.location.hash

/**
  * counter act browser default behaviour
  */
if ( hash ) scroll(0,0);
// void some browsers issue
setTimeout( function() { scroll(0,0); }, 0);


function init() {
  if ( hash ) scrollTo(hash)
}


window.addEventListener('load', init)
// document.addEventListener('load', init)
